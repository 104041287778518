import GqlEvents from "@insightfulscience/shared-utils/gqlEvents";
import { PersistentStorage } from "../persistent-storage";
import * as PurchaseType from "../../purchaseTypes";

const gql = GqlEvents();

const getEmailFromVars = ({ email }) => email;

const regAuthSuccessEvent = (type, getEmail = getEmailFromVars) => (vars, result) =>
	global.dataLayer.push({
		event: "Authenticated",
		user: {
			id: result.signInV2?.customer.customerID || result.signUpV2?.customer.customerID,
			email: getEmail(vars),
		},
		data: {
			authentication: { type },
		},
	});

const regAuthFailEvent = type => (_, { originalError: { message } }) =>
	global.dataLayer.push({
		event: "Returned Authentication Error",
		data: {
			authentication: { type },
			error: { msg: message },
		},
	});

gql.on("signInV2", regAuthSuccessEvent("Login"));
gql.onError("signInV2", regAuthFailEvent("Login"));

gql.on("signUpV2", regAuthSuccessEvent("Register"));
gql.onError("signUpV2", regAuthFailEvent("Register"));

const purchaseGtmEvent = new Map([
	[PurchaseType.SUBSCRIPTION, "Purchased Subscription"],
	[PurchaseType.RENEW, "Renewed Subscription"],
	[PurchaseType.ADDON, "Purchased Subscription Seats"],
]);

const sendRegularPurchseEvent = (orderNumber, order) => {
	const { purchaseType, seats, price, subscriptionSize } = PersistentStorage().priceItem.read();
	const event = purchaseGtmEvent.get(purchaseType) ?? "unknown purchase event";
	global.dataLayer.push({
		event,
		data: {
			planCode: order.subscription.plan.planCode,
			quantity: purchaseType === PurchaseType.RENEW ? subscriptionSize : seats,
			revenue: Math.round(price * 100),
			subscriptionId: order.subscriptionID,
			orderNumber,
		},
	});
};

const sendUpgradeTrialPurchseEvent = (orderNumber, order) => {
	const { price, seats } = PersistentStorage().priceItem.read();

	global.dataLayer.push({
		event: "Purchased Trial",
		data: {
			planCode: order.subscription.plan.planCode,
			quantity: seats + 1,
			revenue: Math.round(price * 100),
			subscriptionId: order.subscriptionID,
			isTrialUpgrade: 1,
			orderNumber,
		},
	});
};

gql.on("purchaseV2", (_, { purchaseV2: { orderNumber, order, errorCode } }) => {
	if (errorCode) return;

	if (order.subscription?.isFromTrial) {
		sendUpgradeTrialPurchseEvent(orderNumber, order);
	} else {
		sendRegularPurchseEvent(orderNumber, order);
	}
});

gql.on("cancelSubscriptionByIDV2", ({ subscriptionId, planCode }) => {
	global.dataLayer.push({
		event: "Canceled Subscription",
		data: {
			planCode,
			subscriptionId,
		},
	});
});

gql.on("reactivateSubscriptionByIDV2", ({ subscriptionID, planCode }) => {
	global.dataLayer.push({
		event: "Reactivated Subscription",
		data: {
			planCode,
			subscriptionId: subscriptionID,
		},
	});
});

gql.on("updatePaymentMethodV2", ({ subscriptionId }) => {
	global.dataLayer.push({
		event: "Changed Subscription Payment Method",
		data: {
			paymentMethod: "Card",
			subscriptionId,
		},
	});
});

gql.on(
	"updateSubscriptionByIDV2",
	(
		{ subscriptionID, planCode, newSeats },
		{ updateSubscriptionByIDV2: { orderNumber, totalAmount } },
	) => {
		global.dataLayer.push({
			event: "Added Subscription Seats",
			data: {
				planCode,
				quantity: newSeats,
				revenue: Math.round(totalAmount * 100),
				subscriptionId: subscriptionID,
				orderNumber,
			},
		});
	},
);

gql.on("requestTrial", ({ email }) => {
	global.dataLayer.push({
		event: "Requested Trial",
		data: {
			user: {
				email,
			},
		},
	});
});

gql.on("startTrial", ({ extension: { roleSelect } }) => {
	global.dataLayer.push({
		event: "Started Trial",
		data: {
			roleSelect,
		},
	});
});

const gqlEventsListener = () => gql.listener;

export default gqlEventsListener;
